<template>
	<svg v-if="src && getIcon(src)">
		<use :xlink:href="`#${icon}`" />
	</svg>
</template>

<script>
export default {
	name: 'BaseIcon',
	props: {
		src: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			icon: null
		};
	},
	methods: {
		getIcon() {
			try {
				const
					{src} = this,
					icon = (src.includes('animals/'))
						? require(`scharfzahn-svg/${src}?sprite`)
						: require(`@/assets/icons/${src}?sprite`)
				;
				this.icon = (icon) ? icon.default.id : null;
				return this.icon;
			} catch (e) {
				// I want application to not crush, but don't care about the message
			}
		}
	}
};
</script>
